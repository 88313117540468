import global from "./global.js";
import popover from "./popover";
import autocomplete from "./autocomplete";
import cascadeselect from "./cascadeselect";
import checkbox from "./checkbox";
import colorpicker from "./colorpicker";
import datepicker from "./datepicker";
import floatlabel from "./floatlabel";
import iconfield from "./iconfield";
import inputgroup from "./inputgroup";
import inputotp from "./inputotp";
import inputgroupaddon from "./inputgroupaddon";
import inputmask from "./inputmask";
import inputnumber from "./inputnumber";
import inputtext from "./inputtext";
import knob from "./knob";
import listbox from "./listbox";
import multiselect from "./multiselect";
import password from "./password";
import radiobutton from "./radiobutton";
import rating from "./rating";
import select from "./select";
import selectbutton from "./selectbutton";
import slider from "./slider";
import textarea from "./textarea";
import togglebutton from "./togglebutton";
import toggleswitch from "./toggleswitch";
import treeselect from "./treeselect";
import button from "./button";
import speeddial from "./speeddial";
import splitbutton from "./splitbutton";
import accordion from "./accordion";
import card from "./card";
import deferred from "./deferred";
import divider from "./divider";
import fieldset from "./fieldset";
import panel from "./panel";
import scrollpanel from "./scrollpanel";
import splitter from "./splitter";
import stepper from "./stepper";
import tabs from "./tabs";
import toolbar from "./toolbar";
import datatable from "./datatable";
import dataview from "./dataview";
import orderlist from "./orderlist";
import organizationchart from "./organizationchart";
import paginator from "./paginator";
import picklist from "./picklist";
import tree from "./tree";
import treetable from "./treetable";
import timeline from "./timeline";
import confirmpopup from "./confirmpopup";
import confirmdialog from "./confirmdialog";
import dialog from "./dialog";
import drawer from "./drawer";
import tooltip from "./tooltip";
import fileupload from "./fileupload";
import breadcrumb from "./breadcrumb";
import contextmenu from "./contextmenu";
import dock from "./dock";
import menu from "./menu";
import menubar from "./menubar";
import megamenu from "./megamenu";
import panelmenu from "./panelmenu";
import tabmenu from "./tabmenu";
import tieredmenu from "./tieredmenu";
import message from "./message";
import toast from "./toast";
import carousel from "./carousel";
import galleria from "./galleria";
import image from "./image";
import avatar from "./avatar";
import badge from "./badge";
import blockui from "./blockui";
import chip from "./chip";
import inplace from "./inplace";
import metergroup from "./metergroup";
import scrolltop from "./scrolltop";
import skeleton from "./skeleton";
import progressbar from "./progressbar";
import progressspinner from "./progressspinner";
import ripple from "./ripple";
import tag from "./tag";
import terminal from "./terminal";
import virtualscroller from "./virtualscroller";

export default {
  global,
  directives: {
    tooltip,
    ripple,
  },
  popover,
  autocomplete,
  cascadeselect,
  checkbox,
  colorpicker,
  datepicker,
  floatlabel,
  iconfield,
  inputgroup,
  inputotp,
  inputgroupaddon,
  inputmask,
  inputnumber,
  inputtext,
  knob,
  listbox,
  multiselect,
  password,
  radiobutton,
  rating,
  select,
  selectbutton,
  slider,
  textarea,
  togglebutton,
  toggleswitch,
  treeselect,
  button,
  speeddial,
  splitbutton,
  accordion,
  card,
  deferred,
  divider,
  fieldset,
  panel,
  scrollpanel,
  splitter,
  stepper,
  tabs,
  toolbar,
  datatable,
  dataview,
  orderlist,
  organizationchart,
  paginator,
  picklist,
  tree,
  treetable,
  timeline,
  confirmpopup,
  confirmdialog,
  dialog,
  drawer,
  fileupload,
  breadcrumb,
  contextmenu,
  dock,
  menu,
  menubar,
  megamenu,
  panelmenu,
  tabmenu,
  tieredmenu,
  message,
  toast,
  carousel,
  galleria,
  image,
  avatar,
  badge,
  blockui,
  chip,
  inplace,
  metergroup,
  scrolltop,
  skeleton,
  progressbar,
  progressspinner,
  tag,
  terminal,
  virtualscroller
};
