export function initializeThemeControls() {
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const theme = localStorage.theme || (prefersDarkScheme ? 'dark' : '');

  document.documentElement.classList.toggle('dark', theme === 'dark');
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();
    //this is how you clear cache now hard reload
    window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
    // window.location.reload();
  });
}
