export default {
    root: {
        class: [
            'relative overflow-auto [contain:strict] [transform: translateZ(0)] will-change-scroll [outline: 0 none]'
        ]
    },
    content: {
        class: [
            'absolute top-0 left-0 min-h-full min-w-full will-change-transform',
        ]
    },
    spacer: {
        class: [
            'absolute top-0 left-0 h-px w-px [transform-origin: 0 0] pointer-events-none'
        ]
    }
};
